exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archives-js": () => import("./../../../src/pages/archives.js" /* webpackChunkName: "component---src-pages-archives-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preferrd-index-js": () => import("./../../../src/pages/preferrd/index.js" /* webpackChunkName: "component---src-pages-preferrd-index-js" */),
  "component---src-pages-priorit-index-js": () => import("./../../../src/pages/priorit/index.js" /* webpackChunkName: "component---src-pages-priorit-index-js" */),
  "component---src-pages-spendoy-index-js": () => import("./../../../src/pages/spendoy/index.js" /* webpackChunkName: "component---src-pages-spendoy-index-js" */)
}

